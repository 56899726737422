<template>
    <div>
        <el-dialog
            :visible.sync="surplusCount"
            width="860px"
            height="480px"
            :before-close="handleClose"
            :close-on-press-escape="canCloseDeptRenewal"
            :close-on-click-modal="canCloseDeptRenewal"
            :show-close="canCloseDeptRenewal"
            center
        >
            <div class="logo">
                <img style="margin-left: -2%" src="../../assets/guide/bahaodilogo.png" alt="" />
            </div>
            <h1 class="tips_title">八号地综合零售云系统</h1>
            <div style="text-align: center; font-size: 20px; color: red">到期未续费将影响您的正常使用，请及时续费</div>
            <div style="overflow-y: auto; height: 230px">
                <div class="tips_text" v-for="(item, index) in list" :key="index">
                    <p>
                        {{ item.name }}门店 系统到期时间为{{ item.effectiveTime
                        }}<span v-if="item.isOverTime == 0" style="color: red">（已过期）</span>
                    </p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button
                    v-if="hasPrivilege('biz.system.deptPrivilege.renewal')"
                    type="primary"
                    @click="toAdministration"
                    >去续费</el-button
                >
                <el-button
                    v-if="!hasPrivilege('biz.system.deptPrivilege.renewal') && canCloseDeptRenewal"
                    type="primary"
                    @click="surplusCount = false"
                    >知道了</el-button
                >
            </span>
        </el-dialog>
        <el-row :gutter="20" style="margin-top: 8px">
            <el-col :span="4">
                <el-card class="deptInit title" :body-style="cardBodyStyle">
                    <img class="title-icon" style="margin-left: -10px" src="@/assets/guide/chushihua.png" />
                    <span class="font">门店初始化</span>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card class="trade title" :body-style="cardBodyStyle">
                    <img class="title-icon" style="margin-left: -10px" src="@/assets/guide/jingying.png" />
                    <span class="font">日常经营</span>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card class="manage title" :body-style="cardBodyStyle">
                    <img class="title-icon" src="@/assets/guide/guanli.png" />
                    <span class="font">公司管理</span>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card class="report title" :body-style="cardBodyStyle">
                    <img class="title-icon" style="margin-left: -10px" src="@/assets/guide/baobiao.png" />
                    <span class="font">报表</span>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card class="other title" :body-style="cardBodyStyle">
                    <img class="title-icon" src="@/assets/guide/qita.png" />
                    <span class="font">其他</span>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="4">
                <el-card :body-style="itemsCardBodyStyle" style="height: 600px">
                    <el-button type="text" @click="showDeptOperateGoodsGuide" class="deptInit_items_btn">
                        导入旧系统数据
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="deptInit_items_btn" @click="showDeptOperateStaffGuide">
                        添加员工与角色
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="deptInit_items_btn" @click="showDeptImportMemberGuide">
                        导入旧系统会员
                    </el-button>
                    <el-divider />
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card :body-style="itemsCardBodyStyle" style="height: 600px">
                    <el-button type="text" class="trade_items_btn" @click="showPurchaseGoodsGuide">
                        采购与入库
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="trade_items_btn" @click="showAllocateGoodsGuide">调拨</el-button>
                    <el-divider />
                    <!--                    <el-button type="text" class="trade_items_btn" disabled>促销管理</el-button>
                    <el-divider />-->
                    <el-button type="text" class="trade_items_btn" @click="showCashTradeGuide">收银/预售单</el-button>
                    <el-divider />
                    <el-button type="text" class="trade_items_btn" @click="showSaleOrderGuide">
                        批发销售/出库单
                    </el-button>
                    <el-divider />
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card :body-style="itemsCardBodyStyle" style="height: 600px">
                    <el-button type="text" class="manage_items_btn" @click="showDailyMonthSettleGuide">
                        日结/月结
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="manage_items_btn" @click="showStockLossSurplusGuide">
                        报溢报损
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="manage_items_btn" @click="showReceiveGuide">内购/领用</el-button>
                    <el-divider />
                    <el-button type="text" class="manage_items_btn" @click="showStockCheckGuide">盘点</el-button>
                    <el-divider />
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card :body-style="itemsCardBodyStyle" style="height: 600px">
                    <!--                    <el-button type="text" class="report_items_btn" disabled>月结报表</el-button>
                    <el-divider />-->
                    <el-button type="text" class="report_items_btn" @click="showStockInOutReportGuide">
                        出入库统计
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="report_items_btn" @click="showPurchaseReportGuide">
                        采购统计
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="report_items_btn" @click="showMerchandisingReportGuide">
                        商品计划统计
                    </el-button>
                    <el-divider />
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card :body-style="itemsCardBodyStyle" style="height: 600px">
                    <el-button type="text" class="other_items_btn" @click="showAddMemberGuide">添加会员</el-button>
                    <el-divider />
                    <el-button type="text" class="other_items_btn" @click="showAppPopularizeStaffGuide">
                        成为app推广员工
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="other_items_btn" @click="showRegisterWechatAlipayGuide">
                        注册微信支付宝
                    </el-button>
                    <el-divider />
                    <el-button type="text" class="other_items_btn" @click="showApplyDeptGuide">申请新门店</el-button>
                    <el-divider />
                </el-card>
            </el-col>
        </el-row>
        <DeptRenewalBizDialog ref="deptRenewalBizDialog" @closeDeptRenewalBizDialog="() => {}" />
    </div>
</template>

<script>
import Util from 'js/Util';
import DeptRenewalBizDialog from 'views/menu/system/component/DeptRenewalBizDialog';
const cardBodyStyleConst = 'display: flex;justify-content: center;align-items: center;';

export default {
    name: 'Guide',
    components: { DeptRenewalBizDialog },
    data() {
        return {
            surplusCount: false,
            surplusDept: 0,
            canCloseDeptRenewal: false,
            //已经过期的机构数据
            overDepts: [],
            list: [],
            cardBodyStyle: cardBodyStyleConst,
            itemsCardBodyStyle: cardBodyStyleConst + 'flex-direction: column;',
        };
    },
    mounted() {
        this.getInformation();
        this.getList();
    },
    methods: {
        toAdministration() {
            if (this.overDepts.length > 0) {
                this.surplusCount = false;
                const _depts = [];
                this.overDepts.forEach((row) =>
                    _depts.push({ renewalShop: row.name, deptCode: row.code, renewalCode: null })
                );
                this.$nextTick(() => {
                    if (this.$refs.deptRenewalBizDialog) this.$refs.deptRenewalBizDialog.show(_depts, true);
                });
            } else {
                if (!this.hasPrivilege('menu.system.dept.show')) {
                    this.$message.warning('您没有查询机构的权限,无法进行续费');
                } else {
                    this.surplusCount = false;
                    this.$router.push({ name: 'menu.system.dept' });
                }
            }
        },
        getInformation() {
            this.$http.get('/system/notice/checkBounced', {}).then((res) => {
                this.canCloseDeptRenewal = res.data.data.canClose;
                this.overDepts = res.data.data.overDepts || [];
                localStorage.setItem('checkButton', res.data.data.checkButton);
                localStorage.setItem('canCloseDeptRenewalBizDialog', res.data.data.canClose);
                if (this.overDepts.length > 0) {
                    this.toAdministration();
                } else {
                    if (res.data.data.surplusDept == 1) {
                        this.surplusCount = true;
                    } else if (res.data.data.surplusDept == 0) {
                        this.surplusCount = false;
                    }
                }
            });
        },
        getList() {
            this.$http.get('/system/notice/deptNameAndDate', {}).then((res) => {
                this.list = res.data.data;
            });
        },
        showDeptOperateGoodsGuide() {
            Util.nameJump(this, 'deptOperateGoodsGuide');
        },
        showDeptOperateStaffGuide() {
            Util.nameJump(this, 'deptOperateStaffGuide');
        },
        showDeptImportMemberGuide() {
            Util.nameJump(this, 'deptImportMemberGuide');
        },
        showPurchaseGoodsGuide() {
            Util.nameJump(this, 'purchaseGoodsGuide');
        },
        showAllocateGoodsGuide() {
            Util.nameJump(this, 'allocateGoodsGuide');
        },
        showCashTradeGuide() {
            Util.nameJump(this, 'cashTradeGuide');
        },
        showSaleOrderGuide() {
            Util.nameJump(this, 'saleOrderGuide');
        },
        showDailyMonthSettleGuide() {
            Util.nameJump(this, 'dailyMonthSettleGuide');
        },
        showStockLossSurplusGuide() {
            Util.nameJump(this, 'stockLossSurplusGuide');
        },
        showReceiveGuide() {
            Util.nameJump(this, 'receiveGuide');
        },
        showStockCheckGuide() {
            Util.nameJump(this, 'stockCheckGuide');
        },
        showStockInOutReportGuide() {
            Util.nameJump(this, 'stockInOutReportGuide');
        },
        showPurchaseReportGuide() {
            Util.nameJump(this, 'purchaseReportGuide');
        },
        showMerchandisingReportGuide() {
            Util.nameJump(this, 'merchandisingReportGuide');
        },
        showAddMemberGuide() {
            Util.nameJump(this, 'addMemberGuide');
        },
        showAppPopularizeStaffGuide() {
            Util.nameJump(this, 'appPopularizeStaffGuide');
        },
        showRegisterWechatAlipayGuide() {
            Util.nameJump(this, 'registerWechatAlipayGuide');
        },
        showApplyDeptGuide() {
            Util.nameJump(this, 'applyDeptGuide');
        },
        handleClose(done) {
            done();
        },
    },
};
</script>

<style scoped>
.deptInit {
    background: linear-gradient(90deg, #7874ff, #dab4fe);
}

.deptInit_items_btn {
    color: #7370ff;
}

.trade_items_btn {
    color: #ff7b57;
}

.manage_items_btn {
    color: #fe5585;
}

.report_items_btn {
    color: #36bf5d;
}

.other_items_btn {
    color: #51a2ff;
}

.trade {
    background: linear-gradient(90deg, #fe8887, #f6c59a);
}

.manage {
    background: linear-gradient(90deg, #fd76a6, #ffaad6);
}

.report {
    background: linear-gradient(90deg, #37dd95, #aaffc4);
}

.other {
    background: linear-gradient(90deg, #51a2ff, #abd2ff);
}

.title {
    height: 100px;
    box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.font {
    font-size: 26px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
}

.el-button--text {
    font-size: 22px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    line-height: 28px;
}

.title-icon {
    width: 60px;
    height: 60px;
    padding-right: 10px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding: 20px 0;
}

.el-divider--horizontal {
    display: block;
    height: 1px;
    width: 70%;
    margin: 24px 0;
}
.logo {
    text-align: center;
}
.tips_text {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: black;
    line-height: 44px;
}
.tips_title {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-align: center;
}
</style>
