<template>
    <div>
        <el-dialog
            title="八号地综合零售云系统续费"
            width="700px"
            :modal="!rightSmallDialogFlag"
            :append-to-body="!rightSmallDialogFlag"
            :visible.sync="showRenewalVisible"
            :close-on-click-modal="canClose"
            :close-on-press-escape="canClose"
            :show-close="canClose"
            :fullscreen="rightSmallDialogFlag"
            @close="closeDeptRenewalBizDialog"
        >
            <el-form label-width="120px">
                <div v-for="(dept, idx) in depts" style="padding-bottom: 10px" :key="idx">
                    <el-card>
                        <el-form-item label="服务名称:">八号地综合零售云系统</el-form-item>
                        <el-form-item label="续费机构:">{{ dept.renewalShop }}</el-form-item>
                        <el-form-item label="续费码:">
                            <el-input v-model="dept.renewalCode" size="small" style="width: 450px"></el-input>
                        </el-form-item>
                    </el-card>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleRowRenewalOk" size="small">续 费</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'DeptRenewalBizDialog',
    data() {
        return {
            showRenewalVisible: false,
            depts: [],
            canClose: null,
            rightSmallDialogFlag: false,
        };
    },
    methods: {
        async handleRowRenewalOk() {
            const _params = [];
            await this.depts.forEach((_dept) => {
                _params.push({
                    deptCode: _dept.deptCode,
                    renewalCode: _dept.renewalCode,
                });
            });
            await this.$http
                .post('/system/renewCode/bind', _params, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((res) => {
                    this.$message.success('授权成功');
                    this.showRenewalVisible = false;
                    this.$emit('closeDeptRenewalBizDialog');
                    this.$store.commit('addOverDepts', []);
                });
        },
        show(_depts = [], canClose = true, rightSmallDialogFlag = false) {
            this.canClose = canClose;
            this.depts = _depts;
            this.showRenewalVisible = true;
            this.rightSmallDialogFlag = rightSmallDialogFlag;
        },
        closeDeptRenewalBizDialog() {
            this.$emit('closeDeptRenewalBizDialog');
        },
    },
};
</script>
